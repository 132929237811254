import axios from 'axios'
import { getAccessToken } from './service'
import {
  VARIABLES,
  getCookieByName,
  removeCookie,
  saveTokenCookie,
} from '../utils'

const api = axios.create({
  baseURL: VARIABLES.API_ENDPOINT,
})

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = getCookieByName(VARIABLES.ACCESS_TOKEN)
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => Promise.reject(error),
)

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = getCookieByName(VARIABLES.REFRESH_TOKEN)
      getAccessToken({
        refreshToken,
      })
        .then((tokenData) => {
          const { accessToken, refreshToken: newRefreshToken } = tokenData.data

          saveTokenCookie(accessToken, newRefreshToken)

          // Retry the original request with the new token
          originalRequest.headers.Authorization = accessToken
          return axios(originalRequest)
        })
        .catch((error) => {
          removeCookie()
          // redirect to login page
          window.location.replace('/')
        })
    } else if (
      error.response?.status === 403 &&
      error?.response?.data?.code === 'token_required'
    ) {
      removeCookie()
      window.location.replace('/')
    } else {
      return Promise.reject(error)
    }
  },
)

export default api
