import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import {
  ExportReport,
  PageHeader,
  TrafficSummaryCardsSection,
  TrafficSummaryFilters,
  TrafficSummaryShimmer,
} from '../components'
import { allCampaignsOption } from '../constants'
import { TimezoneContext } from '../contexts'
import { VARIABLES, getCookieByName } from '../utils'
import { getTrafficSummaryReport } from '../rest-client/service'
import { defaultErrorMessage } from '../utils/constants'

export const TSFilterInitialValue = {
  sentFrom: '',
  sentTill: '',
  campaigns: allCampaignsOption.value,
  allCampaignsArray: [],
}

const TrafficSummaryReport = () => {
  const [isFetching, setIsFetching] = useState(true)
  const [reportData, setReportData] = useState([])
  const [filters, setFilters] = useState(TSFilterInitialValue)
  const { timezone } = useContext(TimezoneContext)
  const accountId = getCookieByName(VARIABLES.ACCOUNTID)
  const toast = useToast()

  const getTrafficSummaryReportData = useCallback(
    async (newFilters) => {
      const filtersParams = newFilters ? newFilters : filters
      const allCampaignsArray = newFilters
        ? newFilters?.allCampaignsArray
        : filters.allCampaignsArray
      if (
        (filtersParams?.sentFrom || filtersParams?.sentTill) &&
        !(filtersParams?.sentFrom && filtersParams?.sentTill)
      )
        return
      setIsFetching(true)
      getTrafficSummaryReport({
        ...(filtersParams?.sentFrom && { sentFrom: filtersParams?.sentFrom }),
        ...(filtersParams?.sentTill && { sentTill: filtersParams?.sentTill }),
        ...(filtersParams?.campaigns === allCampaignsOption.value &&
          filtersParams?.sentFrom &&
          filtersParams?.sentTill && {
            campaigns: allCampaignsArray,
          }),
        ...(filtersParams?.campaigns !== allCampaignsOption.value && {
          campaigns: filtersParams?.campaigns,
        }),
        accountId,
        timezone,
      })
        .then((data) => {
          const { data: response } = data
          setIsFetching(false)
          setReportData(response)
        })
        .catch((error) => {
          const { response } = error
          toast({
            description: response?.data?.message || defaultErrorMessage,
            status: 'error',
          })
          setReportData({})
          setIsFetching(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, timezone],
  )

  useEffect(() => {
    getTrafficSummaryReportData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone])

  const renderPageHeader = () => {
    return (
      <PageHeader
        title='Traffic Summary'
        renderRightSide={() => {
          return (
            <ExportReport
              // 1) !(filters?.campaigns || filters?.sentTill || filters?.sentFrom)
              // campaign, sentTill or sentFrom one of are empty - disabled export report button and drop-down
              // 2) isFetching is true
              // disabled export report button and drop-down
              // 3) Object.keys(reportData).length === 0
              // After API, API response store in reportData variable, if this variable is empty object (has no data) - disabled export report button and drop-down
              isDisabled={
                !(
                  filters?.campaigns ||
                  filters?.sentTill ||
                  filters?.sentFrom
                ) ||
                isFetching ||
                Object.keys(reportData).length === 0
              }
              filters={{
                ...(filters?.sentFrom && {
                  sentFrom: filters?.sentFrom,
                }),
                ...(filters?.sentTill && {
                  sentTill: filters?.sentTill,
                }),
                ...(filters?.campaigns === allCampaignsOption.value &&
                  filters?.sentFrom &&
                  filters?.sentTill && {
                    campaigns: filters.allCampaignsArray,
                  }),
                ...(filters?.campaigns !== allCampaignsOption.value && {
                  campaigns: filters?.campaigns,
                }),
                accountId,
                timezone,
              }}
            />
          )
        }}
      />
    )
  }

  const renderHelmet = () => {
    return (
      <Helmet>
        <title>{'Traffic Summary | ReplyCX'}</title>
        <meta name='title' content={'Traffic Summary | ReplyCX'}></meta>
        <meta property='og:title' content={'Traffic Summary | ReplyCX'}></meta>
        <meta
          property='twitter:title'
          content={'Traffic Summary | ReplyCX'}></meta>
      </Helmet>
    )
  }

  return (
    <React.Fragment>
      {renderHelmet()}
      <Flex as='main' role='main' flexDirection='column' h='100vh'>
        {renderPageHeader()}
        <TrafficSummaryFilters
          isFetching={isFetching}
          onApply={(data) => {
            setFilters(data)
            getTrafficSummaryReportData(data)
          }}
        />
        {isFetching ? (
          <TrafficSummaryShimmer />
        ) : (
          <React.Fragment>
            {Object.keys(reportData).length === 0 ? (
              <Flex justify='center' align='center' h='100%'>
                <Text as='div' className='s5' color='gray' mt='6'>
                  No traffic summary.
                </Text>
              </Flex>
            ) : (
              <Box as='div'>
                <TrafficSummaryCardsSection reportData={reportData} />
              </Box>
            )}
          </React.Fragment>
        )}
      </Flex>
    </React.Fragment>
  )
}

export default TrafficSummaryReport
