import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { HomeIcon } from '../assetsComponents'
import { useUserService } from '../../hooks'

const BackToHomeBtn = () => {
  const navigate = useNavigate()
  const { user } = useUserService()
  return (
    <Button
      leftIcon={<HomeIcon />}
      colorScheme='teal'
      size='lg'
      onClick={() => {
        navigate(user ? `${user?.accountId}/traffic-summary` : '/', {
          replace: true,
        })
      }}>
      Back to Home
    </Button>
  )
}

export default BackToHomeBtn
