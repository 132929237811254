import React from 'react'
import {
  Box,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { channelMappingObject } from '../../constants'

const AdvanceReportTable = ({
  data,
  isFetching,
  currentPage,
  pageSize,
  channel,
}) => {
  const renderMessage = (message) => {
    return (
      <React.Fragment>
        {message ? (
          <Box
            textAlign='left'
            as='div'
            width='400px'
            dangerouslySetInnerHTML={{ __html: message }}
          />
        ) : (
          'N/A'
        )}
      </React.Fragment>
    )
  }

  return (
    <Box as='section' my='4'>
      <TableContainer className='custom-table table-header-fixed'>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Sent Date</Th>
              <Th>Sent At</Th>
              <Th>Sender Id</Th>
              <Th>Destination Id</Th>
              <Th>Message</Th>
              <Th>First Name</Th>
              <Th>Phone Number</Th>
              <Th>Email</Th>
              <Th>Link Tracking</Th>
              {/* <Th>Link Clicked</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {isFetching ? (
              <Tr>
                <Td colSpan='10' textAlign='center'>
                  <Spinner size='sm' />
                </Td>
              </Tr>
            ) : (
              <React.Fragment>
                {data?.map((report, index) => (
                  <Tr key={index}>
                    <Td>{index + 1 + pageSize * (currentPage - 1)}</Td>
                    <Td>{report?.sentDate ? report?.sentDate : 'N/A'}</Td>
                    <Td>{report?.sentTime ? report?.sentTime : 'N/A'}</Td>
                    <Td>{report?.senderId}</Td>
                    <Td>{report?.destinationNo}</Td>
                    <Td
                      textAlign='center'
                      whiteSpace='normal'
                      wordBreak='break-word'>
                      {renderMessage(report?.message)}
                    </Td>
                    <Td>{report?.firstName}</Td>
                    <Td>{report?.number ? report?.number : 'N/A'}</Td>
                    <Td>
                      <Box
                        as='div'
                        width={
                          channel === channelMappingObject.email ? '250px' : ''
                        }
                        whiteSpace='normal'
                        wordBreak='break-word'>
                        {report?.email ? report?.email : 'N/A'}
                      </Box>
                    </Td>
                    <Td>{report?.linkTracking ? 'Enabled' : 'Disabled'}</Td>
                    {/* <Td>{true ? 'Yes' : 'No'}</Td> */}
                  </Tr>
                ))}
              </React.Fragment>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default AdvanceReportTable
