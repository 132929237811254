import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, SimpleGrid, useToast } from '@chakra-ui/react'
import { CampaignSelection, ReportDatePicker } from '../common'
import { allCampaignsOption } from '../../constants'
import { TSFilterInitialValue } from '../../pages'
import { VARIABLES, getCookieByName } from '../../utils'
import { getReportCampaigns } from '../../rest-client/service'
import { defaultErrorMessage } from '../../utils/constants'

const TrafficSummaryFilters = ({ isFetching, onApply }) => {
  const [sentFrom, setSentFrom] = useState('')
  const [sentTill, setSentTill] = useState('')
  const [selectedCampaign, setSelectedCampaign] = useState(
    allCampaignsOption.value,
  )
  const [campaigns, setCampaigns] = useState({
    isLoading: true,
    list: [allCampaignsOption],
    allCampaignsArray: [],
  })
  const accountId = getCookieByName(VARIABLES.ACCOUNTID)
  const toast = useToast()

  useEffect(() => {
    const getCampaigns = async () => {
      getReportCampaigns({
        ...(sentFrom && sentTill && { sentFrom: sentFrom }),
        ...(sentFrom && sentTill && { sentTill: sentTill }),
        accountId,
      })
        .then((data) => {
          const { data: response } = data
          const campaignsList = response.map((campaign) => ({
            label: campaign?.bot_name,
            value: campaign?.id,
          }))
          setCampaigns({
            isLoading: false,
            list: [allCampaignsOption, ...campaignsList],
            allCampaignsArray: campaignsList.map((campaign) => campaign.value),
          })
        })
        .catch((error) => {
          const { response } = error
          toast({
            description: response?.data?.message || defaultErrorMessage,
            status: 'error',
          })
          setCampaigns({
            isLoading: false,
            list: [allCampaignsOption],
          })
        })
    }
    if (campaigns.isLoading) getCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns.isLoading])

  const resetFilter = () => {
    setSentFrom('')
    setSentTill('')
    setSelectedCampaign(allCampaignsOption.value)
    setCampaigns({ isLoading: true, list: [allCampaignsOption] })
    onApply(TSFilterInitialValue)
  }

  return (
    <Box as='section'>
      <SimpleGrid minChildWidth='230px' spacingX='40px' spacing='5px'>
        <ReportDatePicker
          sent={sentFrom}
          setSent={setSentFrom}
          sentTill={sentTill}
          isFetching={isFetching}
          setCampaigns={setCampaigns}
          setSelectedCampaign={setSelectedCampaign}
          sentKey={'sentFrom'}
          helperText='Sent From'
          isRequired={!sentFrom && sentTill}
        />
        <ReportDatePicker
          sent={sentTill}
          setSent={setSentTill}
          sentTill={sentFrom}
          isFetching={isFetching}
          setCampaigns={setCampaigns}
          setSelectedCampaign={setSelectedCampaign}
          sentKey={'sentTill'}
          helperText='Sent Till'
          isRequired={sentFrom && !sentTill}
        />
        <CampaignSelection
          selectedCampaign={selectedCampaign}
          campaigns={campaigns}
          isFetching={isFetching}
          setSelectedCampaign={setSelectedCampaign}
        />
      </SimpleGrid>
      <Flex justify='end'>
        <Button
          m='2'
          title={
            (sentFrom || sentTill) && !(sentFrom && sentTill)
              ? 'Please select "Sent From" and "Sent Till"'
              : ''
          }
          variant='outline'
          isDisabled={
            isFetching || ((sentFrom || sentTill) && !(sentFrom && sentTill))
          }
          colorScheme='green'
          onClick={() =>
            onApply({
              sentFrom,
              sentTill,
              campaigns:
                selectedCampaign === allCampaignsOption.value
                  ? selectedCampaign
                  : [selectedCampaign],
              allCampaignsArray: campaigns.allCampaignsArray,
            })
          }>
          Apply
        </Button>
        <Button
          m='2'
          variant='outline'
          isDisabled={
            !(
              sentTill ||
              sentFrom ||
              selectedCampaign !== allCampaignsOption.value
            ) || isFetching
          }
          onClick={resetFilter}>
          Reset
        </Button>
      </Flex>
    </Box>
  )
}

export default TrafficSummaryFilters
