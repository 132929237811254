import React from 'react'
import { ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects'
import { Box, SimpleGrid } from '@chakra-ui/react'

const TrafficSummaryShimmer = () => {
  return (
    <React.Fragment>
      {[1, 2, 3].map((index) => (
        <React.Fragment key={index}>
          <Box as='div' width='25%' my='3'>
            <ShimmerTitle line={1} variant='primary' />
          </Box>
          <SimpleGrid
            spacing={4}
            templateColumns='repeat(auto-fill, minmax(200px, 1fr))'>
            <ShimmerThumbnail height={160} rounded />
            <ShimmerThumbnail height={160} rounded />
            <ShimmerThumbnail height={160} rounded />
            <ShimmerThumbnail height={160} rounded />
          </SimpleGrid>
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}

export default TrafficSummaryShimmer
