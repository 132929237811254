export const allCampaignsOption = {
  label: 'All Campaigns',
  value: 'All',
}

export const channelMapping = [
  {
    key: '4',
    label: 'SMS',
  },
  {
    key: '5',
    label: 'Whatsapp',
  },
  {
    key: '8',
    label: 'Email',
  },
]

export const channelMappingObject = {
  sms: '4',
  whatsapp: '5',
  email: '8',
}

export const channelStatusMapping = {
  4: [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'sent',
      label: 'Sent',
    },
    {
      value: 'delivered',
      label: 'Delivered',
    },
    {
      value: 'failed',
      label: 'Failed',
    },
  ],
  5: [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'sent',
      label: 'Sent',
    },
    {
      value: 'delivered',
      label: 'Delivered',
    },
    {
      value: 'seen',
      label: 'Seen',
    },
    {
      value: 'replied',
      label: 'Replied',
    },
    {
      value: 'failed',
      label: 'Failed',
    },
  ],
  8: [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'sent',
      label: 'Sent',
    },
    {
      value: 'delivered',
      label: 'Delivered',
    },
    {
      value: 'seen',
      label: 'Seen',
    },
    {
      value: 'replied',
      label: 'Replied',
    },
    {
      value: 'failed',
      label: 'Failed',
    },
    {
      value: 'clicked',
      label: ' Clicked',
    },
  ],
}
