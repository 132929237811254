import { getCookieByName } from './cookie'
import { VARIABLES } from './variables'
import { getExpiresDate } from './utility'

const accountId = getCookieByName(VARIABLES.ACCOUNTID)

export const baseUrl = `/${accountId}`

export const homePageUrl = `${baseUrl}/traffic-summary`

export const getHomepageUrl = (id) => {
  const _id = id ? id : accountId
  return `${_id}/traffic-summary`
}

export const cookieExpires = getExpiresDate(30) // 30 days after expired

export const defaultErrorMessage =
  'Something went wrong! Please try again later.'
