import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  FormControl,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react'
import { FormHelperText, InputField, SelectionField } from '../form'
import { CampaignSelection, ReportDatePicker } from '../common'
import {
  allCampaignsOption,
  channelMapping,
  channelStatusMapping,
} from '../../constants'
import { ARFilterInitialValue } from '../../pages'
import { VARIABLES, getCookieByName } from '../../utils'
import { getReportCampaigns } from '../../rest-client/service'
import { defaultErrorMessage } from '../../utils/constants'

const AdvanceReportFilters = ({ isFetching, onApply }) => {
  const [sentFrom, setSentFrom] = useState('')
  const [sentTill, setSentTill] = useState('')
  const [selectedCampaign, setSelectedCampaign] = useState(
    allCampaignsOption.value,
  )
  const [channel, setChannel] = useState('')
  const [channelStatus, setChannelStatus] = useState('')
  const [message, setMessage] = useState('')
  const [senderId, setSenderId] = useState('')
  const [destinationNo, setDestinationNo] = useState('')
  const [campaigns, setCampaigns] = useState({
    isLoading: true,
    list: [allCampaignsOption],
    allCampaignsArray: [],
  })
  const accountId = getCookieByName(VARIABLES.ACCOUNTID)
  const toast = useToast()

  useEffect(() => {
    const getCampaigns = async () => {
      getReportCampaigns({
        ...(sentFrom && sentTill && { sentFrom: sentFrom }),
        ...(sentFrom && sentTill && { sentTill: sentTill }),
        accountId,
      })
        .then((data) => {
          const { data: response } = data
          const campaignsList = response.map((campaign) => ({
            label: campaign?.bot_name,
            value: campaign?.id,
          }))
          setCampaigns({
            isLoading: false,
            list: [allCampaignsOption, ...campaignsList],
            allCampaignsArray: campaignsList.map((campaign) => campaign.value),
          })
        })
        .catch((error) => {
          const { response } = error
          toast({
            description: response?.data?.message || defaultErrorMessage,
            status: 'error',
          })
          setCampaigns({
            isLoading: false,
            list: [allCampaignsOption],
          })
        })
    }
    if (campaigns.isLoading) getCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns.isLoading])

  const resetFilter = () => {
    setSentFrom('')
    setSentTill('')
    setSelectedCampaign(allCampaignsOption.value)
    setChannel('')
    setChannelStatus('')
    setMessage('')
    setSenderId('')
    setDestinationNo('')
    setCampaigns({
      isLoading: true,
      list: [allCampaignsOption],
      allCampaignsArray: [],
    })
    onApply(ARFilterInitialValue)
  }

  return (
    <Box as='section'>
      <SimpleGrid minChildWidth='230px' spacingX='40px' spacing='5px'>
        <ReportDatePicker
          sent={sentFrom}
          setSent={setSentFrom}
          sentTill={sentTill}
          isFetching={isFetching}
          setCampaigns={setCampaigns}
          setSelectedCampaign={setSelectedCampaign}
          sentKey={'sentFrom'}
          helperText='Sent From'
          isRequired={!sentFrom && sentTill}
        />
        <ReportDatePicker
          sent={sentTill}
          setSent={setSentTill}
          sentTill={sentFrom}
          isFetching={isFetching}
          setCampaigns={setCampaigns}
          setSelectedCampaign={setSelectedCampaign}
          sentKey={'sentTill'}
          helperText='Sent Till'
          isRequired={sentFrom && !sentTill}
        />
        <CampaignSelection
          selectedCampaign={selectedCampaign}
          campaigns={campaigns}
          isFetching={isFetching}
          setSelectedCampaign={setSelectedCampaign}
        />
        <FormControl my='2'>
          <SelectionField
            placeholder={!channel ? 'Select Channel' : ''}
            value={channel}
            onChange={(e) => {
              const channelValue = e.target.value
              setChannel(channelValue)
              setChannelStatus('')
            }}
            options={channelMapping.map((channel) => ({
              value: channel?.key,
              label: channel?.label,
            }))}
            getValueAsOption
            isDisabled={isFetching}
          />
          <FormHelperText text='Select Channel' />
        </FormControl>
        <FormControl my='2'>
          <SelectionField
            placeholder={!channelStatus && !channel ? 'Select EDR Status' : ''}
            value={channelStatus}
            onChange={(e) => {
              setChannelStatus(e.target.value)
            }}
            title={
              !channel ? 'Please select channel first to choose EDR status' : ''
            }
            options={channelStatusMapping[channel]}
            getValueAsOption
            isDisabled={isFetching || !channel}
          />
          <FormHelperText text='Select EDR Status' />
        </FormControl>
        <InputField
          placeholder='Message'
          type='text'
          my='2'
          value={message}
          onChange={(e) => {
            setMessage(e.target.value)
          }}
          isDisabled={isFetching}
          size='md'
          formGroupClassName='mt-0'
        />
        <InputField
          placeholder='Sender ID'
          my='2'
          type='text'
          value={senderId}
          onChange={(e) => {
            setSenderId(e.target.value)
          }}
          isDisabled={isFetching}
          size='md'
          formGroupClassName='mt-0'
        />
        <InputField
          placeholder='Destination No'
          my='2'
          type='text'
          value={destinationNo}
          onChange={(e) => {
            setDestinationNo(e.target.value)
          }}
          isDisabled={isFetching}
          size='md'
          formGroupClassName='mt-0'
        />
      </SimpleGrid>
      <Flex justify='end'>
        <Button
          m='2'
          variant='outline'
          title={
            !channel
              ? 'Please select "Channel"'
              : (sentFrom || sentTill) && !(sentFrom && sentTill)
                ? 'Please select "Sent From" and "Sent Till"'
                : ''
          }
          isDisabled={
            isFetching ||
            ((sentFrom || sentTill) && !(sentFrom && sentTill)) ||
            !channel
          }
          colorScheme='green'
          onClick={() =>
            onApply({
              sentFrom,
              sentTill,
              campaigns:
                selectedCampaign === allCampaignsOption.value
                  ? selectedCampaign
                  : [selectedCampaign],
              channel,
              channelStatus,
              message,
              senderId,
              destinationNo,
              allCampaignsArray: campaigns.allCampaignsArray,
              currentPage: 1,
            })
          }>
          Apply
        </Button>
        <Button
          m='2'
          variant='outline'
          isDisabled={
            !(
              sentTill ||
              sentFrom ||
              channel ||
              channelStatus ||
              message ||
              senderId ||
              destinationNo ||
              selectedCampaign !== allCampaignsOption.value
            ) || isFetching
          }
          onClick={resetFilter}>
          Reset
        </Button>
      </Flex>
    </Box>
  )
}

export default AdvanceReportFilters
